module.exports = {
  giphyApiKey: "P8hPvgoFDv0RxNy05pqsoEcEs3dAMAMT",
  firebaseConfig: {
    apiKey: "AIzaSyD-8xIt3JiL_1_SFr_sR5uOgQ_sjG52Vvg",
    authDomain: "giffindor-webapp.firebaseapp.com",
    databaseURL: "https://giffindor-webapp.firebaseio.com",
    projectId: "giffindor-webapp",
    storageBucket: "giffindor-webapp.appspot.com",
    messagingSenderId: "284468476292",
    appId: "1:284468476292:web:9055c3d12f61909d3145d1",
    measurementId: "G-4K5HQ2700V",
  },
};
